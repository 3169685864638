



































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, DinoDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import DisplayName from '@/components/DisplayName.vue';
import PlayerName from '@/components/PlayerName.vue';
import DinoName from '@/components/DinoName.vue';
import ServerName from '@/components/ServerName.vue';
import TribeName from '@/components/TribeName.vue';


Vue.use(VueAsyncComputed);

@Component({
    components: {DisplayName, DinoName, PlayerName, ServerName, TribeName}
})
export default class DinoById extends Vue {
    @Prop({default: ''}) private id!: string;
    errorNoSuchDino = false;

    private dateFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} as const;

    public serverUrl(serverId: number) {
        return `https://ark.domination-gaming.com/server/${serverId}`;
    }

    public tribeUrl(serverId: number, tribeId: number) {
        return `https://ark.domination-gaming.com/server/${serverId}/tribe/${tribeId}`;
    }

    public tribeUrlArkStats(serverId: number, tribeId: number) {
        return `/tribe/${serverId}-${tribeId}`;
    }

    public survivorUrl(serverId: number, survivorId: number) {
        return `https://ark.domination-gaming.com/server/${serverId}/survivor/${survivorId}`;
    }

    @AsyncComputedProp()
    public async dino(): Promise<DinoDto> {
        const dino = (await ArkStatsBackendClient.getDinos({
            filter: 'id==' + this.id,
            limit: 1
        })).data[0];
        if (!dino) {
            this.errorNoSuchDino = true;
        }
        return dino;
    }

    @AsyncComputedProp()
    public async children(): Promise<DinoDto[]> {
        const dinos = (await ArkStatsBackendClient.getDinos({
            filter: 'fatherId==' + this.id + ',motherId==' + this.id
        })).data;
        return dinos;
    }

    public localDate(timestamp: string | Date): string {
        return new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(new Date(timestamp));
    }

}

